<template>
    <div>
        <v-card>
          <h2 class="pl-2">
            Oferty {{type == 0 ? 'Dla Ciebie' : 'Dla Sprzedawcy'}}
          </h2>

          <v-btn
            color="blue"
            class="float-right mr-10 white--text"
            @click="addOrEditArticle()">
            Dodaj ofertę
            <v-icon class="ml-2">mdi-archive-plus</v-icon>
          </v-btn>

          <div style="clear:both"></div>

          <v-card-text>
            <v-row
              align="center"
              justify="center"
            >
              <v-btn-toggle
                v-model="dateFilter"
                mandatory
              >
                <v-btn>
                  Aktualne
                </v-btn>
                <v-btn>
                  Przyszłe
                </v-btn>
                <v-btn>
                  Archiwalne
                </v-btn>
              </v-btn-toggle>
            </v-row>
          </v-card-text>

          <v-data-table
            :headers=headers
            :items=articles
            :options.sync="options"
            :server-items-length="serverItemsLength"
          >
          <template slot="item.type"  slot-scope="props">
            {{props.item.type == ArticleTypesEnum.YOU ? "Dla Ciebie" : "Dla Sprzedawcy"}}
          </template>
            <template slot="item.actions" slot-scope="props" class="justify-center">
              <v-btn
                class="mx-2"
                icon
                @click="addOrEditArticle(props.item)"
              ><v-icon dark>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                class="mx-2"
                icon
                @click="articleStatistics(props.item)"
              ><v-icon dark>mdi-account-tie</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
    </div>
</template>

<script>
import { ArticleTypesEnum } from '@/utils/enums.js'

export default {
  name: 'Articles',
  props: {
    type: {
      type: Number,
      requred: true
    }
  },
  data: () => ({
    dateFilter: null,
    headers: [],
    articles: [],
    options: {},
    serverItemsLength: 0
  }),
  async beforeMount () {
    this.ArticleTypesEnum = ArticleTypesEnum

    this.headers = [
      { text: this.$t('articles.name'), value: 'name' },
      { text: 'Rodzaj oferty', value: 'type' },
      { text: this.$t('articles.from'), value: 'from', class: 'mw-110' },
      { text: this.$t('articles.to'), value: 'to', class: 'mw-110' },
      { text: this.$t('articles.description'), value: 'description' },
      { text: 'Wizyty', value: 'visitedCount', sortable: false },
      { text: 'Likes', value: 'likedCount', sortable: false },
      { text: 'Dislikes', value: 'dislikedCount', sortable: false },
      { text: this.$t('common.actions'), value: 'actions', sortable: false, width: '15%' }
    ]

    this.endpoint = '/Article/GetArticles' + '?type=' + this.type
  },
  methods: {
    async getDataAsync () {
      let url = this.endpoint
      url += '&dateFilter=' + this.dateFilter

      if (this.options.page) {
        url += '&options.page=' + this.options.page + '&' +
        'options.itemsPerPage=' + this.options.itemsPerPage + '&' +
        'options.sortBy=' + this.options.sortBy + '&' +
        'options.sortDesc=' + this.options.sortDesc
      }

      const result = await this.$http.get(url)
      this.articles = result.data.results
      this.serverItemsLength = result.data.totalCount
    },
    async articleStatistics (item) {
      await this.$router.push({ name: 'Statystki oferty', params: { id: item.id } })
    },
    async addOrEditArticle (item) {
      await this.$router.push({ name: this.type === ArticleTypesEnum.YOU ? 'offer-for-you' : 'offer-for-salesman', params: { id: (item ? item.id : null) } })
    }
  },
  computed: {
    variablesResponsibleForFetchNewData () {
      return `${this.options}|${this.dateFilter}`
    }
  },
  watch: {
    variablesResponsibleForFetchNewData: {
      handler () {
        this.getDataAsync()
      },
      deep: true
    }
  }
}
</script>
